import {collection, db, getDoc, getDocs, query, doc} from "./firebase";

export async function dbFindAllUtil (path, ...queryConstraints) {
  const list = []
  const listCollection = collection(db, path)
  const q = query(listCollection, ...queryConstraints)

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push({
      id: doc.id,
      ...doc.data()
    })
  });
  return list
}

export async function dbFindUtil (path) {
  const docRef = doc(db, path)
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return {
      id: docSnap.id,
      ...docSnap.data()
    }
  }
  return null
}

export async function dbGetUserstUtil (path) {
  const users = []
  const usersCollection = collection(db, path)
  const q = query(usersCollection)
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    users.push({
      id: doc.id,
      ...doc.data()
    })
  });
  return users
}
