import axios from './_axios';
import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail, where, functionsEndpoint, sortBy
} from './firebase.js'
import {dbFindAllUtil, dbFindUtil} from './fbDbUtils.js'
import jwt_decode from 'jwt-decode'
import _axios from './_axios';
import {orderBy} from "firebase/firestore";


const STORAGE_KEY = 'scolados-fb'
const FUNCTIONS_ENDPOINT = functionsEndpoint

export function getAccessToken() {
  return auth.currentUser?.accessToken || ""
}

export function getUser() {
  const user = localStorage.getItem(STORAGE_KEY)

  if (user) {
    return JSON.parse(user)
  } else {
    return null
  }
}

export function isAuthenticated() {
  const user = getUser()

  return !!user
}

export async function doLogin(userEmail, userPass) {
  const userFromDb = await dbFindAllUtil('users', where('email', '==', userEmail))
  if(!userFromDb[0] || userFromDb[0].role != 'adm-system') {
    throw new Error('Sem permissão')
  }
  await signInWithEmailAndPassword(auth, userEmail, userPass)


  const { accessToken, displayName, email, photoURL } = auth.currentUser

  const user = { accessToken, displayName, email, photoURL }

  localStorage.setItem(STORAGE_KEY, JSON.stringify(user))
}

export async function doLogout() {
  await signOut(auth)

  localStorage.removeItem(STORAGE_KEY)

  location.reload()
}

export async function doCreateUser(userName, userEmail, password, roles, schools, schoolsRoles) {
  // const userCredential = await createUserWithEmailAndPassword(auth, userEmail, password)
  // const { email, uid } = userCredential.user
  const userToDb = {
    userName,
    password,
    email: userEmail,
    // id: uid,
    role: roles,
    schools,
    disabled: false,
    schoolsRoles
  }

  await _axios.post(`${FUNCTIONS_ENDPOINT}/admin/users`, userToDb)
  return 'OK'
}

export async function getUsersFromDb() {
  return await dbFindAllUtil('users', orderBy('userName'))
}

export async function getUsersByRole(role) {
  return await dbFindAllUtil('users', where("role", "==", role))
}

export async function getSchoolUsers(schoolId) {
  return await dbFindAllUtil('users', where("schools", "array-contains", schoolId))
}

//@deprecated
export async function doEditUser(userName, userEmail, roles, schools, disabled) {
  const userToDb = {
    userName,
    email: userEmail,
    role: roles,
    schools,
    disabled,
  }

  await axios.put(`${FUNCTIONS_ENDPOINT}/users/editUser`, { user: userToDb })
  return 'OK'
}

export async function editUser(userId, user) {
  await axios.put(`${FUNCTIONS_ENDPOINT}/users/${userId}`, user)
  return 'OK'
}

export async function doResetPassword(userEmail) {
  try {
    await sendPasswordResetEmail(auth, userEmail)
    return 'OK'
  } catch (e) {
    console.log(`reset password ERROR: ${e.message}`)
    throw e
  }
}

export async function getUserById(userId) {
  return await dbFindUtil(`users/${userId}`)
}

export function isSysAdmin(){
  const user = getUser()
  const {role} =  jwt_decode(user.accessToken)
  return role != 'adm-system' ? false : true
}


